import * as React from "react"
import tw from "twin.macro"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContentContainer from "../components/ContentContainer"
import ContentTitle from "../components/ContentTitle"
import Content from "../components/Content"

const SectionContainer = tw.div`mb-8`
const SectionTitle = tw.h2`text-lg text-3xl font-semibold mb-2`

const FormField = tw.div`flex flex-col mb-4`

const ContactPage = () => {
  const email = "info@longrockcapital.co.uk"

  return (
    <Layout>
      <React.Fragment>
        <SEO title="Contact us | Long Rock Capital" />
        <ContentContainer>
          <ContentTitle>Contact us</ContentTitle>
          <Content>
            <SectionContainer>
              <form method="post" action="https://www.flexyform.com/f/f456d20eb67a50e65234a4a84f6e4f2bd88f3df7">
                <FormField>
                  <label>Full name</label>
                  <input type="text" name="fullname" />
                </FormField>
                <FormField>
                  <label>Email</label>
                  <input type="email" name="_reply_to" />
                </FormField>
                <FormField>
                  <label>Message</label>
                  <textarea name="message" rows={5}></textarea>
                </FormField>

                <input type="hidden" name="_recaptcha" id="_recaptcha"/>
                <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"></script>
                <script src="https://www.flexyform.com/js/recaptcha.js"></script>

                <button
                  type="submit"
                  tw="px-8 py-4 rounded text-white bg-black hover:bg-gray-600 w-full md:w-auto"
                >
                  Send
                </button>
              </form>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>General enquiries</SectionTitle>
              <p>Email: <a tw="underline hover:text-gray-700" href={`mailto:${email}`}>{email}</a></p>
            </SectionContainer>
            {
              false &&
              <SectionContainer>
                <SectionTitle>Address</SectionTitle>
                <p>
                  Long Rock Capital<br />
                  Spaces, 30 Moorgate<br />
                  London, U.K.<br />
                  EC2R 6PJ
                </p>
              </SectionContainer>
            }
          </Content>
        </ContentContainer>
      </React.Fragment>
    </Layout>
  )
}

export default ContactPage